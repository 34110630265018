// token
export const TOKEN = 'token'

// token时间戳
export const TIME_STAMP = 'timeStamp'

// token 超时时长
export const TOKEN_TIMEOUT_VALUE = 7 * 24 * 60 * 60 * 1000

// 金额是否显示
export const SHOW_MONEY = 'show_money'

// 类型：收入
export const INCOME = 'income'

// 类型：支出
export const EXPENDITURE = 'expenditure'
