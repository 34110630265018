import { getItem, removeAllItem, setItem } from '../../utils/storeage'
import { SHOW_MONEY, TOKEN } from '../../constant'
import router from '../../router'
import { getUserInfo, login } from '../../api/user'
import { setTimeStamp } from '../../utils/auth'

export default {
  namespace: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
    showMoney: getItem(SHOW_MONEY) === null ? false : getItem(SHOW_MONEY)
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setShowMoney(state, data) {
      state.showMoney = data
      setItem(SHOW_MONEY, data)
    }
  },
  actions: {
    // 登录请求动作
    login(context, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          this.commit('setToken', res.data.token)
          setTimeStamp()
          router.push('/')
          resolve(res.data)
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取用户信息
    async getUserInfo() {
      const res = await getUserInfo()
      this.commit('setUserInfo', res.data)
      return res
    },
    // 退出登录
    logout() {
      this.commit('setToken', '')
      this.commit('setUserInfo', {})
      removeAllItem()
      router.push('/login')
    }
  }
}
