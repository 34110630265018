import 'vant/lib/index.css'
import {
  Toast,
  Button,
  SwipeCell,
  Dialog,
  Popup,
  DatetimePicker,
  Picker,
  List,
  Image as VanImage,
  Row,
  Col,
  DropdownMenu,
  DropdownItem
} from 'vant'

export function vant(app) {
  app.use(Toast)
  app.use(Dialog)
  app.use(Button)
  app.use(SwipeCell)
  app.use(Popup)
  app.use(DatetimePicker)
  app.use(Picker)
  app.use(List)
  app.use(VanImage)
  app.use(Row)
  app.use(Col)
  app.use(DropdownMenu)
  app.use(DropdownItem)
}
