import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/add',
    name: 'add',
    component: () => import(/* webpackChunkName: "add" */ '../views/Add')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login')
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics')
  },
  {
    path: '/allRecord',
    name: 'allRecord',
    component: () => import(/* webpackChunkName*: "allRecord" */ '../views/AllRecord')
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 白名单页面
const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  if (store.getters.token) {
    // 用户已登录，不允许进入 login
    if (to.path === '/login') {
      next('/')
    } else {
      if (!store.getters.hasUserInfo) {
        store.dispatch('getUserInfo')
      }
      next()
    }
  } else {
    // 用户未登录，则只允许进入白名单页面
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
