<template>
  <div class="user-info">
    <div class="avatar"><img :src="store.getters.userInfo.user_avatar" alt=""></div>
    <div class="content">
      <p class="user-name">{{ store.getters.userInfo.user_name }}</p>
      <p class="today-date">{{ currentDay }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import dayjs from 'dayjs'

const store = useStore()

const currentDay = ref(dayjs().format('YYYY年MM月DD日'))
</script>

<style scoped lang="less">
.user-info {
  display: flex;
  .avatar {
    width: 43px;
    height: 43px;
    border-radius: 14px;
    background-color: #eee;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    margin-left: 13px;
    .user-name {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #333;
      margin-top: 2px;
    }
    .today-date {
      font-size: 11px;
      color: #999;
      margin-top: 2px;
      line-height: 15px;
    }
  }
}
</style>
