<template>
  <van-swipe-cell>
    <div class="record-item">
      <div class="item-left">
        <van-image width="46" height="46" fit="contain" :src="item.classify.icon" />
        <div class="item-content">
          <p class="item-classify-name">{{ item.classify.name }}</p>
          <p class="item-remarks">{{ item.remark }}</p>
        </div>
      </div>
      <div class="item-right">
        <p class="item-amount">{{ item.type === 'income' ? '+' : '-' }} {{ $filters.formatMoney(item.amount) }}</p>
        <p class="item-date">{{ $filters.formatDate(item.create_time) }}</p>
      </div>
    </div>
    <template #right>
      <van-button square type="danger" text="删除" @click="clickDelete" />
    </template>
  </van-swipe-cell>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { deleteRecord } from '../../api'
import { Toast, Dialog } from 'vant'

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  }
})

const emits = defineEmits(['on-delete'])

const clickDelete = () => {
  Dialog.confirm({
    title: '提示',
    message: '确定要删除该记录吗？',
    confirmButtonColor: '#5C02D0'
  }).then(() => {
    deleteRecord({ id: props.item.id }).then(res => {
      Toast.success('删除成功')
      emits('on-delete', props.item)
    })
  }).catch(() => {})
}
</script>

<style scoped lang="less">
.record-item {
  padding: 3px 0;
  .item-left {
    max-width: 70%;
    display: flex;
    float: left;
    margin-left: 18px;
    .item-content {
      margin-left: 14px;
    }
  }
  .item-right {
    float: right;
    text-align: right;
    margin-right: 18px;
  }
  .item-classify-name,
  .item-amount {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    line-height: 22px;
  }
  .item-remarks,
  .item-date {
    font-size: 13px;
    color: #999;
    margin-top: 3px;
  }
}
</style>
