<template>
  <div class="record-list">
    <div class="list-header">
      <p class="header-title">最近记录</p>
      <router-link :to="{ name: 'allRecord' }" class="header-all">全部</router-link>
    </div>
    <RecordItem class="record-item" v-for="item in recordList" :key="item.id" :item="item" @on-delete="onDelete" />
  </div>
</template>

<script setup>
import RecordItem from '../../../components/RecordItem'

import { ref, defineEmits } from 'vue'
import { getRecord } from '../../../api'

const emits = defineEmits(['on-deleteRecord'])

const recordList = ref([])
const getRecordList = async () => {
  const res = await getRecord({ date_sort: 'high' })
  recordList.value = res.data.list
}
getRecordList()

const onDelete = (item) => {
  getRecordList()
  emits('on-deleteRecord', item)
}
</script>

<style scoped lang="less">
@import '../../../assets/css/common';
.record-list {
  margin-top: 43px;
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 18px;
    .header-title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    .header-all {
      color: @primaryColor;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .record-item {
    margin-top: 18px;
  }
}
</style>
