import { POST, GET } from '../utils/request'

// 登录
export const login = (data) => POST('/user/login', data)

// 注册
export const register = (data) => POST('/user/reg', data)

// 获取用户信息
export const getUserInfo = () => GET('/user')
