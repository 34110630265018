
const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  hasUserInfo: state => JSON.stringify(state.user.userInfo) !== '{}',
  showMoney: state => state.user.showMoney,
  cssVar: state => state.user.CssVar
}

export default getters
