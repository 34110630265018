<template>
  <div class="show-money">
    <p class="module-title">收支平衡</p>
    <p class="balance-money">
      {{ store.getters.showMoney ? $filters.formatMoney(money.balance) || 0 : '******' }}
      <span :class="['iconfont', store.getters.showMoney ? 'icon-openeye' : 'icon-closeeye']" @click="changeShowMoney"></span>
    </p>
    <div class="money-box">
      <div class="box-item">
        <p class="module-title">
          <span class="iconfont icon-xia"></span>
          收入
        </p>
        <p class="box-item-money">{{ store.getters.showMoney ? $filters.formatMoney(money.income) || 0 : '****' }}</p>
      </div>
      <div class="box-item">
        <p class="module-title">
          <span class="iconfont icon-shang"></span>
          支出
        </p>
        <p class="box-item-money">{{ store.getters.showMoney ? $filters.formatMoney(money.expenditure) || 0 : '****' }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue'
import { getIncomeExpenditure } from '../../../api'
import { useStore } from 'vuex'

const store = useStore()

const money = ref({})
const getMoney = async (date = undefined) => {
  const res = await getIncomeExpenditure({ date })
  money.value = res.data
}
getMoney()

const changeShowMoney = () => {
  store.commit('setShowMoney', !store.getters.showMoney)
}

defineExpose({
  getMoney
})
</script>

<style scoped lang="less">
.show-money {
  background-color: #6A00F1;
  border-radius: 18px;
  padding: 27px 24px 18px;
  text-align: center;
  position: relative;
  color: #fff;
  .module-title {
    font-size: 13px;
    font-weight: 500;
    color: rgba(255,255,255,.6);
  }
  .balance-money {
    font-weight: bold;
    font-size: 45px;
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    .iconfont {
      font-size: 24px;
      margin-left: 10px;
      margin-top: 3px;
    }
  }
  .money-box {
    display: flex;
    justify-content: space-around;
    background-color: #5300BF;
    border-radius: 13px;
    padding: 14px 0;
    margin-top: 26px;
    .box-item {
      line-height: 1;
      flex: 1;
      &:nth-child(1) {
        border-right: 1px solid rgba(255,255,255,.49);
      }
      .box-item-money {
        margin-top: 8px;
      }
      .iconfont {
        font-size: 12px;
      }
      .icon-xia {
        color: #5FD774;
      }
      .icon-shang {
        color: #FE5F62;
      }
    }
  }
}
</style>
