import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/index.less'
import 'amfe-flexible'

import { vant } from './config/vant.config'
import installFilter from './filters'
import './registerServiceWorker'

const app = createApp(App)
vant(app)
installFilter(app)
app
  .use(store)
  .use(router)
  .mount('#app')
