import dayjs from 'dayjs'

// 对金额进行除100处理
export const formatMoney = (val) => {
  return val / 100
}

// 对日期进行处理
export const formatDate = (val) => {
  if (dayjs(val).isSame(dayjs(), 'day')) {
    return '今天'
  }
  if (dayjs(val).isSame(dayjs().subtract(1, 'day'), 'day')) {
    return '昨天'
  }
  if (dayjs(val).isSame(dayjs(), 'year')) {
    return dayjs(val).format('M月DD日')
  }
  return dayjs(val).format('YYYY年MM月DD日')
}

export default app => {
  app.config.globalProperties.$filters = {
    formatMoney,
    formatDate
  }
}
