import { GET, POST, DELETE } from '../utils/request'

// 获取分类
export const getClassify = () => GET('/classify')

// 新增记录
export const addRecord = (data) => POST('/record', data)

// 记录列表
export const getRecord = (data) => GET('/record', data)

// 获取收入支出
export const getIncomeExpenditure = (data) => GET('/record/money', data)

// 删除记录
export const deleteRecord = (data) => DELETE('/record', data)

// 获取常用分类备注
export const topRemark = (data) => GET('/record/top_remark', data)
