<template>
  <div class="home">
    <div class="padding-box">
      <UserInfo />
      <ShowMoney class="show-money" ref="showMoneyDom" />
    </div>
    <RecordList @on-deleteRecord="deleteRecord" />
  </div>
  <MyFooter />
</template>

<script setup>
import UserInfo from './components/UserInfo'
import ShowMoney from './components/ShowMoney'
import RecordList from './components/RecordList'
import MyFooter from '../../components/MyFooter'

import { ref } from 'vue'

const showMoneyDom = ref(null)

const deleteRecord = () => {
  showMoneyDom.value.getMoney()
}
</script>

<style scoped lang="less">
.home {
  .padding-box {
    padding: 16px 18px;
  }
  .show-money {
    margin-top: 18px;
  }
}
</style>
