import axios from 'axios'
import store from '../store'
import { isCheckTimeout } from './auth'
import { Toast } from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截
service.interceptors.request.use(config => {
  if (store.getters.token) {
    if (isCheckTimeout()) {
      store.dispatch('logout')
      return Promise.reject(new Error('token 失效'))
    }

    config.headers.Authorization = `Bearer ${store.getters.token}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
service.interceptors.response.use(
  // 请求成功
  response => {
    const { code, message } = response.data
    // 判断当前请求是否成功
    if (code >= 200 && code < 300) {
      return response.data
    } else {
      if (code === 1002) {
        Toast.fail('请重新登录')
        store.dispatch('logout')
      } else {
        Toast.fail(message)
      }
      return Promise.reject(new Error(message))
    }
  },
  // 请求失败
  error => {
    if (error.response?.data?.code === 401) {
      store.dispatch('logout')
    }
    return Promise.reject(error)
  }
)

export const GET = (url, data) => {
  return service({
    url,
    params: data
  })
}

export const POST = (url, data) => {
  return service({
    url,
    method: 'POST',
    data
  })
}

export const DELETE = (url, data = {}) => {
  const { id } = data
  if (id) {
    url += `/${id}`
    delete data.id
  }
  return service({
    url,
    method: 'DELETE',
    data
  })
}

export default service
