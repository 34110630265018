<template>
  <div class="my-footer">
    <div
      :class="{ 'footer-item': true, 'footer-active-item': item.path === route.path}"
      @click="changeTab(item)"
      v-for="item in tabs"
      :key="item.icon">
      <span :class="['iconfont', item.icon]"></span>
      <p class="footer-item-text">{{ item.name }}</p>
    </div>
    <router-link :to="{ name: 'add' }" class="add-record iconfont icon-add"></router-link>
  </div>
  <div class="empty"></div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const tabs = ref([
  { name: '首页', icon: 'icon-home', path: '/' },
  { name: '统计', icon: 'icon-chart', path: '/statistics' }
])

const changeTab = (item) => {
  router.push(item.path)
}
</script>

<style scoped lang="less">
@import '../../assets/css/common';
.my-footer {
  background: url("../../assets/images/footer-bg.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 72px;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 20;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .footer-item {
    text-align: center;
    padding-top: 8px;
    color: #999;
    .iconfont {
      font-size: 26px;
      font-weight: 500;
    }
    &-text {
      font-size: 12px;
      margin-top: 2px;
    }
  }
  .footer-active-item {
    color: @primaryColor;
  }
  .add-record {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    overflow: hidden;
    background-color: @primaryColor;
    text-align: center;
    line-height: 82px;
    position: absolute;
    left: 50%;
    top: -42px;
    transform: translateX(-50%);
    box-shadow: 0 2px 5px 0 rgba(92,2,208,0.4);
    color: #fff;
    font-size: 22px;
    font-weight: bold;
  }
  a {
    text-decoration: none;
  }
}
.empty {
  width: 100%;
  height: 72px;
}
</style>
